"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { Session } from "next-auth";
import { useInView } from "react-intersection-observer";
import {
  ExternalLink,
  Heart,
  Lock,
  MessageCircle,
  Notebook,
  Plus,
} from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../ui/sheet";

import Logo from "../logo";
import { handleLogin, handleLogout } from "./action";
import { MenuLink } from "./menu-link";
import ThemeToggle from "./theme-toggle";

interface Props {
  session: Session | null;
}

const Navbar = ({ session }: Props) => {
  const [active, setActive] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const { ref, inView } = useInView({
    rootMargin: "-68px",
  });

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    isMounted && setTimeout(() => setActive(!inView), 200);
  }, [inView, isMounted]);

  async function handleAuthClick() {
    if (session?.user) {
      await handleLogout();
    } else {
      await handleLogin();
    }
  }

  return (
    <>
      <div
        className={cn(
          "text-foreground bg-background fixed top-0 left-0 z-10 flex w-full flex-row justify-between fill-current p-4 transition-shadow duration-300 ease-in-out",
          active && !inView ? "shadow-lg" : "shadow-none",
        )}
      >
        <Link
          href="/"
          id="home-link"
          title="Home - Chat interface"
          className="hover:text-primary text-foreground block w-fit transition-colors"
        >
          <Logo />
        </Link>

        <div className="space-x-2">
          <Sheet>
            <ThemeToggle />
            <SheetTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                id="nav"
                title="Open links menu"
              >
                <Plus size={16} />
              </Button>
            </SheetTrigger>

            <SheetContent className="flex flex-col gap-4">
              <SheetHeader className="text-left">
                <SheetTitle>Links</SheetTitle>
                <SheetDescription>
                  You can interact with the whole site using the chat interface.
                </SheetDescription>
              </SheetHeader>

              <div className="flex flex-col gap-4 p-4">
                <MenuLink href="/">
                  <MessageCircle size={20} />
                  Home
                </MenuLink>

                <MenuLink href="/articles">
                  <Notebook size={20} />
                  Projects
                </MenuLink>

                <MenuLink href="/contact" disabled>
                  <Heart size={20} />
                  Contact
                </MenuLink>
              </div>

              <SheetFooter className="flex grow flex-col justify-end sm:flex-col">
                <div className="flex flex-row items-end justify-between">
                  <div className="flex flex-col gap-2">
                    <MenuLink href="terms-of-service">Terms</MenuLink>
                    <MenuLink href="privacy">Privacy</MenuLink>
                    <MenuLink href="imprint">Imprint</MenuLink>
                    <MenuLink
                      href="https://github.com/schlomoh"
                      target="_blank"
                    >
                      Github Profile
                      <ExternalLink size={16} />
                    </MenuLink>
                  </div>

                  <Button
                    id="auth-button"
                    title="Private admin access"
                    onClick={handleAuthClick}
                    variant="ghost"
                    className="flex w-fit gap-2"
                  >
                    {session?.user ? "Logout" : ""}
                    <Lock size={16} />
                  </Button>
                </div>
              </SheetFooter>
            </SheetContent>
          </Sheet>
        </div>
      </div>
      <div id="nav-offset" ref={ref} className="m-navbar" />
    </>
  );
};

export default Navbar;
