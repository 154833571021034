import { useEffect, useState } from "react";
import { useTheme } from "next-themes";
import { motion, AnimatePresence } from "framer-motion";

import { Button } from "../ui/button";
import { Moon, Sun } from "lucide-react";

const variants = {
  show: {
    opacity: 1,
    y: 0,
  },
  hide: {
    opacity: 0,
    y: -10,
  },
};

const ThemeToggle = () => {
  const { theme, setTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  // After mounting, we have access to the theme
  useEffect(() => {
    setMounted(true);

    if (theme === "system") {
      const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
      setTheme(isDark ? "dark" : "light");
    }
  }, [setTheme, theme]);

  function handleThemeChange() {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
  }

  return mounted ? (
    <Button
      size="icon"
      variant="ghost"
      onClick={handleThemeChange}
      className="overflow-hidden"
      id="theme-toggle-button"
      title="Toggle theme mode"
    >
      <AnimatePresence mode="wait">
        <motion.span
          key={theme}
          initial="hide"
          animate="show"
          exit="hide"
          variants={variants}
        >
          {theme === "dark" ? <Sun size={16} /> : <Moon size={16} />}
        </motion.span>
      </AnimatePresence>
    </Button>
  ) : null;
};

export default ThemeToggle;
